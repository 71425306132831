import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogActionsComponent, DialogComponent } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'ntw-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  imports: [TranslateModule, DialogComponent, DialogActionsComponent]
})
export class ConfirmDialogComponent {
  @Input() public close: () => void;
  @Input() public title: string = this.translateService.instant('confirmDialog.defaultTitle');
  @Input() public message: string = this.translateService.instant('confirmDialog.defaultMessage');
  @Output() public decision: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private translateService: TranslateService) { }
}
