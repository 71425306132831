import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadCrumbComponent, BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { CrmAccount } from '../shared/models/account';
import { SecurityService } from '../shared/security/security.service';
import { KendoAlertService } from '../shared/services/kendo-alerts.service';
import { homeIcon } from '@progress/kendo-svg-icons';

@Component({
    selector: 'ntw-routing-breadcrumbs',
    template: `
   @if (this.currentAccount) {
     <kendo-breadcrumb (itemClick)="onItemClick($event)" [items]="items"></kendo-breadcrumb>
   }
   `,
   imports: [BreadCrumbComponent]
})
export class RoutingBreadCrumbsComponent implements OnInit, OnDestroy {
    private readonly _destroying$ = new Subject<void>();

    public items: BreadCrumbItem[] = [];

    private routesData: Subscription;
    public currentAccount: CrmAccount;

    constructor(private router: Router,
        private translateService: TranslateService,
        private securityService: SecurityService,
        private kendoAlertService: KendoAlertService) {
    }

    ngOnInit(): void {
        this.setupLanguageChangeHandler();
        this.securityService.getCurrentAccount().subscribe({
            next: account => {
                this.currentAccount = account;
                if (!this.currentAccount) {
                    return;
                }
                this.routesData = this.router.events.subscribe({
                    next: () => this.createBreadCrumbsFromRoute(),
                    error: error => console.error(error)
                });
            },
            error: error => {
                this.kendoAlertService.showErrorAlert(error);
            }
        });
    }

    public ngOnDestroy(): void {
        this.routesData.unsubscribe();
        this._destroying$.next(null);
        this._destroying$.complete();
    }

    private setupLanguageChangeHandler(): void {
        this.translateService.onLangChange.pipe(takeUntil(this._destroying$)).subscribe(() => {
            if (!this.currentAccount) {
                return;
            }
            this.createBreadCrumbsFromRoute();
        });
    }

    public onItemClick(item: BreadCrumbItem): void {
        const selectedItemIndex = this.items.findIndex(i => i.text === item.text);
        const urlSegments = this.replaceAccountNameWithAccountId(selectedItemIndex);
        this.router.navigate(urlSegments);
    }

    private createBreadCrumbsFromRoute(): void {
        let route: string | undefined = this.router.url;
        route = this.removeAccountIdFromRoute(route);
        const fragments = route?.split('/')
            .map((segment) => {
                switch (segment) {
                    case 'draftorder':
                        return {
                            text: this.translateService.instant('routing.draftOrder'),
                            title: segment
                        };
                    case 'openorders':
                        return {
                            text: this.translateService.instant('routing.openOrders'),
                            title: segment
                        };
                    case 'orders':
                        return {
                            text: this.translateService.instant('routing.placedOrders'),
                            title: segment
                        };
                    case 'invoices':
                        return {
                            text: this.translateService.instant('routing.invoices'),
                            title: segment
                        };
                    case 'products':
                        return {
                            text: this.translateService.instant('routing.products'),
                            title: segment
                        };
                    default:
                        return {
                            text: this.removeCompanyKeyFromString(segment),
                            title: segment
                        };
                }
            }) ?? [];
        this.items = [
            {
                text: this.currentAccount.name,
                title: this.currentAccount.name,
                svgIcon: homeIcon
            },
            ...fragments
        ];
    }

    private removeAccountIdFromRoute(route: string) {
        const currentAccountId = this.currentAccount.accountid
        route = route.slice(route.indexOf(currentAccountId));
        return route.split('/').length > 1 ? route.slice(route.indexOf('/') + 1) : undefined;
    }

    private replaceAccountNameWithAccountId(selectedItemIndex: number) {
        const accountRoute = `account/${this.currentAccount.accountid}`;
        const urlParts = this.items.slice(0, selectedItemIndex + 1);

        const accountNameUrlPart = urlParts.find(x => x.title === this.currentAccount.name);
        if (accountNameUrlPart) {
            accountNameUrlPart.title = accountRoute;
        }

        return urlParts.map(x => x.title);
    }

    private removeCompanyKeyFromString(str: string): string {
        const keySeparator = "_";

        return !str.includes(keySeparator) ? str : str.slice(0, str.indexOf(keySeparator));
    }
}
