<kendo-dialog
  [title]="this.title"
  (close)="close()"
  width="100%"
  height="90vh"
  [maxWidth]="1320"
  maxHeight="90vh"
>
<ng-container>
    <object [data]="this.safePdfSrc" type="application/pdf" width="100%" height="100%">
      <p>{{ 'shared.pdfViewer.noPdfPlugin' | translate}}<a [href]="this.safePdfSrc">{{ 'shared.pdfViewer.noPdfPluginLink' | translate}}</a></p>
    </object>
  </ng-container>
  <kendo-dialog-actions>
    <button class="k-button k-button-md k-rounded-md k-button-solid-base k-button-solid" kendoButton (click)="close()">{{ 'shared.close' | translate}}</button>
  </kendo-dialog-actions>
</kendo-dialog>
