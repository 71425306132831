<div class="d-flex align-items-center w-100">
  <button kendoButton (click)="isTreeViewVisible = !isTreeViewVisible" [disabled]="disabled" class="primaryButton">
    <div class="d-flex align-items-center">
      <kendo-svg-icon [icon]="this.icons.filter"></kendo-svg-icon>
      <div class="d-none d-lg-block" [@slideInOut]="!selectedItem ? 'in' : 'out'">
        <ng-container>{{ 'sku-filter.all-products' | translate }}</ng-container>
      </div>
    </div>
  </button>
  @if (isBreadcrumbVisible) {
    <div class="breadcrumbs-container h-100">
      <ntw-sku-filter-breadcrumbs [items]="selectedItemHierarchy" (itemClick)="breadcrumbClicked($event)">
      </ntw-sku-filter-breadcrumbs>
    </div>
  }
</div>

@if (isTreeViewVisible && !disabled) {
  <div class="treeview-container">
    <button kendoButton class="primaryButton m-2" [svgIcon]="icons.filterClear" (click)="clearSelection()">
      {{ 'sku-filter.clear' | translate }}
    </button>
    <kendo-treeview class="sku-filter-treeview" kendoTreeViewExpandable kendoTreeViewSelectable
      kendoTreeViewFlatDataBinding [nodes]="data" idField="code" textField="text" parentIdField="parentCode"
      selectBy="code" [(selectedKeys)]="selectedKeys" [(expandedKeys)]="expandedKeys"
      (selectionChange)="onSelectionChanged()">
    </kendo-treeview>
  </div>
}
