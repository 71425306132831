<kendo-appbar positionMode="sticky">

  <kendo-appbar-section>
    <a class="inline" [routerLink]="onLogoClick()">
      <!-- <svg-icon class="d-none d-sm-block" src="../../../../assets/navigation-top-bar/logo.svg"></svg-icon> -->
      <!-- <svg-icon class="d-sm-none" src="../../../../assets/navigation-top-bar/logo-small.svg"></svg-icon> -->
      <svg-icon class="d-block me-1" src="../../../../assets/navigation-top-bar/ppg-logo.svg"></svg-icon>
      <svg-icon class="d-block" src="../../../../assets/navigation-top-bar/logo-small.svg"></svg-icon>
    </a>
  </kendo-appbar-section>

  @if (!isMaintenanceActive) {
    <kendo-appbar-spacer ngClass="navigation-top-bar-spacer"></kendo-appbar-spacer>
    <kendo-appbar-section>
      @if (securityService.getUser() && this.securityService.hasAnyRole()) {
        <ntw-account-context-chooser>
        </ntw-account-context-chooser>
      }
    </kendo-appbar-section>
    <kendo-appbar-section id="appbar-right-section1">
      @if (securityService.getUser() && !loading) {
        <ntw-navigation-menu></ntw-navigation-menu>
      }
      <ntw-user-option-chooser class="mx-2"></ntw-user-option-chooser>
    </kendo-appbar-section>
    <kendo-appbar-section id="appbar-right-section2">
      @if (securityService.getUser() && this.securityService.hasAnyRole() && !loading) {
        <button
          class="primaryButton d-flex align-items-center justify-content-center"
          [disabled]="!this.selectedAccount || !this.securityService.canCreateOrders() || isAgentSalesCustomer"
          (click)="newOrderClick()">
          <kendo-svg-icon class="d-sm-none" [icon]="this.icons.cart" size="large"
            style="line-height: 0px; padding: 0px 5px 0px 0px">
          </kendo-svg-icon>
          <kendo-svg-icon class="d-none d-sm-inline-flex" [icon]="this.icons.cart"
            style="line-height: 0px; padding: 0px 5px 0px 0px">
          </kendo-svg-icon>
          <span>{{ 'order.newOrder' | translate}}</span>
        </button>
      }
    </kendo-appbar-section>
  }

  <kendo-appbar-section>
    @if (isNotificationActive && notificationMessage && notificationMessage.length > 0) {
      <div class="notification-banner"
            [ngClass]="{
                'banner-warning': notificationLevel === 'Warning',
                'banner-error': notificationLevel === 'Error'
            }"
        >
        <div class="notification-content" #notificationContent
          [@collapse]="isCollapsed ? 'closed' : 'open'"
          [innerHTML]="notificationMessage">
        </div>
        @if (isNotificationOverflown) {
          <button type="button" class="expand-button button-info d-flex justify-content-center align-items-center"
            [@rotate]="isCollapsed ? 'collapsed' : 'expanded'"
            [attr.aria-expanded]="!isCollapsed"
            (click)="isCollapsed = !isCollapsed"
                [ngClass]="{
                    'button-warning': notificationLevel === 'Warning',
                    'button-error': notificationLevel === 'Error'
                }">
            <kendo-svg-icon [icon]="icons.chevronDown" size="xxlarge"></kendo-svg-icon>
          </button>
        }
        <button class="close-button button-info d-flex justify-content-center align-items-center"
                [ngClass]="{
                    'button-warning': notificationLevel === 'Warning',
                    'button-error': notificationLevel === 'Error'
                }"
          (click)="isNotificationActive = false">
          <kendo-svg-icon [icon]="icons.close" size="xlarge"></kendo-svg-icon>
        </button>
      </div>
    }
  </kendo-appbar-section>

</kendo-appbar>