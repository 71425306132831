import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { filterClearIcon, filterIcon } from '@progress/kendo-svg-icons';
import { Subject, takeUntil } from 'rxjs';
import { BootstrapBreakpoints } from 'src/app/shared/constants/bootstrap-breakpoints';
import { SKUFilterBreadCrumbsComponent } from './sku-filter-breadcrumbs.component';
import { ISkuFilterData, skuFilterData } from './sku-filter-data';

@Component({
  selector: 'ntw-sku-filter',
  templateUrl: './sku-filter.component.html',
  styleUrls: ['./sku-filter.component.scss', '../../../../shared/styles/button.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        marginLeft: '0.125rem',
        maxWidth: '200px', // Adjust this value based on your layout
        opacity: 1
      })),
      state('out', style({
        marginLeft: '0',
        maxWidth: '0',
        opacity: 0
      })),
      transition('* <=> *', [
        animate('0.3s ease')
      ])
    ])
  ],
  imports: [SVGIconModule, TranslateModule, SKUFilterBreadCrumbsComponent, TreeViewModule, ButtonModule, CommonModule, FormsModule]
})
export class SkuFilterComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  @Input() public disabled = false;
  @Output() public filterChanged = new EventEmitter<ISkuFilterData | undefined>();

  icons = {
    filter: filterIcon,
    filterClear: filterClearIcon
  }

  isBreadcrumbVisible = false;
  isTreeViewVisible = false;

  data = skuFilterData.map(item => {
    return {
      ...item,
      text: this.translateService.instant(item.text),
    }
  });

  selectedKeys = [];
  expandedKeys = [];

  get selectedItem(): ISkuFilterData {
    return this.data.find(item => item.code === this.selectedKeys[0]);
  }

  selectedItemHierarchy: ISkuFilterData[] = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadData();
    this.setupBreakpointObserver();
    this.setupLanguageChangeHandler();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  onSelectionChanged(): void {
    this.updateHierarchy();
    this.isTreeViewVisible = false;
    this.filterChanged.emit(this.selectedItem);
  }

  breadcrumbClicked(item: ISkuFilterData): void {
    this.selectedKeys = [item.code];
    this.onSelectionChanged();
  }

  clearSelection(): void {
    this.selectedKeys = [];
    this.onSelectionChanged();
  }

  private updateHierarchy(): void {
    if (!this.selectedItem) {
      this.selectedItemHierarchy = [];
      return;
    }

    const hierarchy = [(this.selectedItem)];

    let parent = this.selectedItem;
    while (parent && parent.parentCode) {
      parent = this.data.find(item => item.code === this.selectedItem.parentCode);
      hierarchy.unshift(parent);
    }

    this.selectedItemHierarchy = hierarchy;
  }

  private loadData(): void {
    this.data = skuFilterData.map(item => {
      return {
        ...item,
        text: this.translateService.instant(item.text),
      }
    });
  }

  private setupBreakpointObserver() {
    this.breakpointObserver.observe([
      BootstrapBreakpoints.xxs,
      BootstrapBreakpoints.xs,
      BootstrapBreakpoints.sm,
      BootstrapBreakpoints.md,
      BootstrapBreakpoints.lg,
      BootstrapBreakpoints.xl,
      BootstrapBreakpoints.xxl
    ]).subscribe(result => {
      if (result.matches) {
        if (result.breakpoints[BootstrapBreakpoints.xxs] || result.breakpoints[BootstrapBreakpoints.xs] || result.breakpoints[BootstrapBreakpoints.sm] || result.breakpoints[BootstrapBreakpoints.md]) {
          this.isBreadcrumbVisible = false;
        }
        else if (result.breakpoints[BootstrapBreakpoints.lg] || result.breakpoints[BootstrapBreakpoints.xl] || result.breakpoints[BootstrapBreakpoints.xxl]) {
          this.isBreadcrumbVisible = true;
        }
      }
    });
  }

  private setupLanguageChangeHandler() {
    this.translateService.onLangChange.pipe(takeUntil(this._destroying$)).subscribe(() => {
      this.loadData();
      this.updateHierarchy();
    })
  }
}
