/// <reference types="@angular/localize" />

import { ElementRef, enableProdMode, importProvidersFrom, provideExperimentalZonelessChangeDetection } from '@angular/core';

import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeSwedish from '@angular/common/locales/sv';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MessageService } from '@progress/kendo-angular-l10n';
import { NOTIFICATION_CONTAINER } from '@progress/kendo-angular-notification';
import { AccountService } from './app/account/services/account.service';
import { IAccountService } from './app/account/services/account.service.interface';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { InvoiceService } from './app/invoice/services/invoice.service';
import { IInvoiceService } from './app/invoice/services/invoice.service.interface';
import { OrderService } from './app/order/services/order.service';
import { IOrderService } from './app/order/services/order.service.interface';
import { PortalOrderService } from './app/portal-order/services/portal-orders.service';
import { IPortalOrderService } from './app/portal-order/services/portal-orders.service.interface';
import { InventoryService } from './app/products/services/inventory.service';
import { IInventoryService } from './app/products/services/inventory.service.interface';
import { ProductService } from './app/products/services/product.service';
import { IProductService } from './app/products/services/product.service.interface';
import { ContextGuardService } from './app/shared/security/guards/context-guard.service';
import { SecurityService } from './app/shared/security/security.service';
import { AddressService } from './app/shared/services/address/address.service';
import { IAddressService } from './app/shared/services/address/address.service.interface';
import { ColorshadeService } from './app/shared/services/colorshade/colorshade.service';
import { IColorshadeService } from './app/shared/services/colorshade/colorshade.service.interface';
import { CompanyAdminConfigurationService } from './app/shared/services/company-admin-configuration/company-admin-configuration.service';
import { ICompanyAdminConfigurationService } from './app/shared/services/company-admin-configuration/company.admin.configuration.service.interface';
import { CompanyNotificationService } from './app/shared/services/company-notification/company-notification.service';
import { ContactService } from './app/shared/services/contact/contact.service';
import { IContactService } from './app/shared/services/contact/contact.service.interface';
import { CustomerSettingsService } from './app/shared/services/customer-settings/customer-settings.service';
import { ICustomerSettingsService } from './app/shared/services/customer-settings/customer-settings.service.interface';
import { CustomerService } from './app/shared/services/customer/customer.service';
import { ICustomerService } from './app/shared/services/customer/customer.service.interface';
import { FilterAndSortDescriptionService } from './app/shared/services/filter-sort-description.service';
import { IdleService } from './app/shared/services/idle/idle.service';
import { KendoAlertService } from './app/shared/services/kendo-alerts.service';
import { KendoTranslationService } from './app/shared/services/kendo-translation.service';
import { CustomLanguageService } from './app/shared/services/language.service';
import { MaintenanceService } from './app/shared/services/maintenance/maintenance.service';
import { OrderCartService } from './app/shared/services/order-cart.service';
import { PimproductsService } from './app/shared/services/pimproducts/pimproducts.service';
import { PricelistService } from './app/shared/services/pricelist/pricelist.service';
import { IPricelistService } from './app/shared/services/pricelist/pricelist.service.interface';
import { PrintService } from './app/shared/services/print/print.service';
import { IPrintService } from './app/shared/services/print/print.service.interface';
import { StorageService } from './app/shared/services/storage/storage-service';
import { GridPersistService } from './app/shared/services/ui/grid-persist.service';
import { UserSettingsService } from './app/shared/services/user/user-settings.service';
import { IUserSettingsService } from './app/shared/services/user/user-settings.service.interface';
import { WarehouseService } from './app/shared/services/warehouse/warehouse.service';
import { CompanyAdminConfigurationStore } from './app/shared/stores/company-admin-configuration.store';
import { CurrentUserContactStore } from './app/shared/stores/current-user-contact.store';
import { CustomerSettingsStore } from './app/shared/stores/customer-settings.store';
import { UserSettingsStore } from './app/shared/stores/user-settings.store';
import { TaskService } from './app/task/services/task.service';
import { ITaskService } from './app/task/services/task.service.interface';
import { environment } from './environments/environment';
import { loginRequest, msalConfig, protectedResources } from './environments/msal-configs/msal-config';
import { NgIdleModule } from '@ng-idle/core';
import { Idle } from '@ng-idle/core';
import { OrderProcessService } from './app/order-process/services/order-process.service';
import { IOrderProcessService } from './app/order-process/services/order.process.service.interface';

if (environment.production) {
    enableProdMode();
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();

    protectedResourceMap.set(protectedResources.api.endpoint, protectedResources.api.scopes);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest
    };
}

registerLocaleData(localeSwedish);

export function HttpLoaderFactory(handler: HttpBackend) {
    const http = new HttpClient(handler);
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, AppRoutingModule, TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpBackend]
            },
        }),
        NgIdleModule.forRoot()),
        { provide: SecurityService, useClass: SecurityService },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
            provide: NOTIFICATION_CONTAINER,
            useFactory: () => {
                return { nativeElement: document.body } as ElementRef;
            }
        },
        { provide: IAddressService, useClass: AddressService },
        { provide: IColorshadeService, useClass: ColorshadeService },
        { provide: ICompanyAdminConfigurationService, useClass: CompanyAdminConfigurationService },
        { provide: IContactService, useClass: ContactService },
        { provide: ICustomerService, useClass: CustomerService },
        { provide: ICustomerSettingsService, useClass: CustomerSettingsService },
        { provide: IPricelistService, useClass: PricelistService },
        { provide: IPrintService, useClass: PrintService },
        { provide: IUserSettingsService, useClass: UserSettingsService },
        { provide: MessageService, useExisting: KendoTranslationService },
        { provide: ITaskService, useClass: TaskService },
        { provide: IAccountService, useClass: AccountService },
        { provide: IInvoiceService, useClass: InvoiceService },
        { provide: IOrderService, useClass: OrderService },
        { provide: IPortalOrderService, useClass: PortalOrderService },
        { provide: IProductService, useClass: ProductService },
        { provide: IInventoryService, useClass: InventoryService },
        { provide: IOrderProcessService, useClass: OrderProcessService },
        { provide: IPricelistService, useClass: PricelistService },
        PortalOrderService,
        MaintenanceService,
        CompanyNotificationService,
        StorageService,
        GridPersistService,
        KendoTranslationService,
        CustomLanguageService,
        PimproductsService,
        CompanyAdminConfigurationStore,
        ContextGuardService,
        CurrentUserContactStore,
        CustomerSettingsStore,
        DatePipe,
        FilterAndSortDescriptionService,
        KendoAlertService,
        OrderCartService,
        StorageService,
        UserSettingsStore,
        WarehouseService,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
    .catch(err => console.error(err));
