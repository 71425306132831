@if (this.confirmClearCartDialogVisible) {
  <ntw-confirm-dialog [close]="this.closeConfirmClearCartDialog"
    title="{{'orderProcess.clearCart' | translate | uppercase}}"
    message="{{'orderProcess.clearCartConfirmation' | translate}}" (decision)="this.onClearCartButtonClicked($event)">
  </ntw-confirm-dialog>
}
@if (!stepsLoading) {
  <div class="row pt-3">
    <div class="col">
      <kendo-stepper [steps]="steps" steptype='label' [linear]="false" [currentStep]="currentStepIdx"
        (activate)="onStepChange($event)">
        <ng-template kendoStepperStepTemplate let-step>
          <div class="custom-step">
            @if (step.icon) {
              <kendo-svg-icon [icon]="step.icon"></kendo-svg-icon>
            }
            @if (!step.icon) {
              <span>{{step.text}}</span>
            }
          </div>
          <span class="step-label">{{ step.label }}</span>
        </ng-template>
      </kendo-stepper>
    </div>
  </div>
}
@if (!stepsLoading) {
<div class="row mt-3 nimbus-sans-font" [ngClass]="{
    'mb-1': this.currentStep.stepName === 'products',
    'mb-3': this.currentStep.stepName !== 'products'
}">
    <div class="col-auto">
      @if (!isFirstStep()) {
        <button kendoButton class="primaryButton fw-bold" [svgIcon]="this.icons.undo"
          (click)="onPreviousButtonClicked()">{{"orderProcess.moveBack" | translate}}
        </button>
      }
    </div>
    <div class="col">
      @if (!isLastStep()) {
        <div class="float-end">
          <button kendoButton class="primaryButton fw-bold" [svgIcon]="this.icons.redo"
            [disabled]="!canProceedToStep(nextStep.stepName)" (click)="onNextButtonClicked()">
            {{"orderProcess.moveNext" | translate}}
          </button>
        </div>
      }
      @if (saveAsDraftButtonVisible()) {
        <div class="float-end pe-2">
          <button kendoButton class="tertiaryButton fw-bold" [svgIcon]="this.icons.save"
            (click)="onSaveButtonClicked()">{{"orderProcess.saveChanges" | translate}}
          </button>
        </div>
      }
      @if (this.currentStep.stepName === 'warehouse' && !this.canSelectWarehouse) {
        <div class="float-end pe-2">
          <button kendoButton class="tertiaryButton fw-bold" [svgIcon]="this.icons.cart"
            (click)="openConfirmClearCartDialog()">{{"orderProcess.clearCart" | translate}}
          </button>
        </div>
      }
    </div>
  </div>
}
@if (loading) {
  <div class="row">
    <ntw-loading-panel></ntw-loading-panel>
  </div>
} @else {
  @if (orderUpdateInProgress) {
    <div class="row">
      <ntw-loading-panel [coverTopBar]="true"></ntw-loading-panel>
    </div>
  }
  @if (currentStep.stepName === 'warehouse') {
    <div class="row">
      <div class="d-none d-lg-block col-1 col-xl-2"></div>
      <div class="col">
        <ntw-order-select-warehouse [isReadOnly]="!this.canSelectWarehouse" [(portalOrder)]="portalOrder"
          (portalOrderChange)="setIsOrderUpdated(true)">
        </ntw-order-select-warehouse>
      </div>
      <div class="d-none d-lg-block col-1 col-xl-2"></div>
    </div>
  }
  @if (currentStep.stepName === 'products') {
    <div class="row">
      <div class="col">
        <ntw-order-select-products class="py-1" [(portalOrder)]="portalOrder" [inventoryOnline]="inventoryOnline"
          (portalOrderChange)="onOrderUpdatedInSelectProductsStep()" [(searchValue)]="productSearchValue">
        </ntw-order-select-products>
      </div>
    </div>
  }
  @if (currentStep.stepName === 'summary') {
    <div class="row">
      <div class="col">
        <ntw-order-summary [(portalOrder)]="portalOrder" (portalOrderChange)="setIsOrderUpdated(true)"
          [pricesUpdatedOn]="pricesUpdatedOn" (getPricesStarted)="onGetPricesStarted()"
          (getPricesFinished)="onGetPricesFinished()" [isEverythingSelected]="verifyIfEverythingIsSelected()"
          [isCompanyNorway]="isCompanyNorway" [termsAndConditionsPath]="termsAndConditionsPath"
        (submitOrderClicked)="submitOrder()"></ntw-order-summary>
      </div>
    </div>
  }
}
