import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { Subject, concatMap, takeUntil } from 'rxjs';
import { GridTemplateComponent } from 'src/app/shared/components/grid-template/grid-template.component';
import { placedOrdersListLineColumnsFormName } from 'src/app/shared/constants/form-names.consts';
import { GridNames } from 'src/app/shared/constants/grid-names';
import { CrmAccount } from 'src/app/shared/models/account';
import { CosmosCustomer } from 'src/app/shared/models/customer';
import { FormConfiguration } from 'src/app/shared/models/form-configuration';
import { KendoGridBase } from 'src/app/shared/models/kendo-grid-base';
import { OrderHeader } from 'src/app/shared/models/orders/order-header';
import { ColumnSettings } from 'src/app/shared/models/ui/column-settings';
import { TitleValue } from 'src/app/shared/models/ui/title-value';
import { SecurityService } from 'src/app/shared/security/security.service';
import { ICustomerService } from 'src/app/shared/services/customer/customer.service.interface';
import { FilterAndSortDescriptionService } from 'src/app/shared/services/filter-sort-description.service';
import { KendoAlertService } from 'src/app/shared/services/kendo-alerts.service';
import { GridPersistService } from 'src/app/shared/services/ui/grid-persist.service';
import { CompanyAdminConfigurationStore } from 'src/app/shared/stores/company-admin-configuration.store';
import { Utils } from 'src/app/shared/utils';
import { IOrderService } from '../services/order.service.interface';
import { orderHeaderColumns } from './order-header-columns';

@Component({
  selector: 'ntw-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
  imports: [GridTemplateComponent]
})
export class OrderListComponent extends KendoGridBase<OrderHeader> implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  public totalValues: TitleValue[];
  public columnSettings: ColumnSettings[];
  private formConfig: FormConfiguration;

  public currentAccount: CrmAccount;
  private currentCustomer: CosmosCustomer;

  constructor(
    private companyAdminConfigurationStore: CompanyAdminConfigurationStore,
    private customerService: ICustomerService,
    private filterSortService: FilterAndSortDescriptionService,
    private gridPersistService: GridPersistService,
    private kendoAlertService: KendoAlertService,
    private orderService: IOrderService,
    private securityService: SecurityService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    this.companyAdminConfigurationStore.getFormConfiguration(placedOrdersListLineColumnsFormName).pipe(concatMap(formConfig => {
      this.formConfig = formConfig;
      return this.securityService.getCurrentAccount();
    }), concatMap(account => {
      this.currentAccount = account;
      return this.customerService.getCustomerByCode(account.ntw_axcode);
    })).subscribe(customer => {
      this.currentCustomer = customer;
      this.columnSettings = FormConfiguration.applyToColumnSettings(this.formConfig, [...orderHeaderColumns]);
      this.setTotalValues();

      this.kendoGridState = this.gridPersistService.GetGridState(GridNames.orderGrid, this.kendoGridState);
      this.currentAccount = this.securityService.getCurrentAccountObject();
      this.setDefaultFiltersAndSort();
      this.getEntitiesForAccount();
    })
    this.translateService.onLangChange.subscribe(() => {
      this.translateTitleValues();
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  public getEntitiesForAccount() {
    if (!this.currentAccount?.ntw_axcode) {
      this.entities = [];
      return;
    }
    this.loading = true;
    this.orderService.getOrdersWithGridState(this.currentAccount.ntw_axcode, this.kendoGridState, this.searchBarFilters).subscribe({
      next: odataResponse => {
        if (odataResponse && odataResponse.value) {
          this.generateGridDataViewOData(odataResponse);
        }
      },
      error: error => {
        this.kendoAlertService.showErrorAlert(error.error);
        this.loading = false;
      }
    });
  }

  override dataStateChange = (state: DataStateChangeEvent) => {
    this.kendoGridState = state;
    this.gridPersistService.SaveGridState(GridNames.orderGrid, state);
    this.getEntitiesForAccount();
  }

  override getQuickSearchFilters(query: string): FilterDescriptor[] {
    const filterArray = [];
    filterArray.push(this.filterSortService.createFilter("tolower(Id)", query.toLocaleLowerCase(), "contains"));
    filterArray.push(this.filterSortService.createFilter("tolower(CustomerRequisition)", query.toLocaleLowerCase(), "contains"));
    filterArray.push(this.filterSortService.createFilter("tolower(CustomerReference)", query.toLocaleLowerCase(), "contains"));
    return filterArray;
  }

  override refreshView(): void {
    this.getEntitiesForAccount();
  }

  public generateSummary = (orders: OrderHeader[]) => {
    this.summaries = [];
    this.summaries = this.graphData.GetGraphDataForOrders(orders);
    this.setTitleValues(orders);
  }

  private setDefaultFiltersAndSort() {
    this.setCanceledSalesStatusFilter();
    this.setOrderDateDecreasingSorting();
  }

  private setCanceledSalesStatusFilter() {
    const cancelledStatus = "Canceled"
    const statusFilter = this.filterSortService.createFilter("SalesStatus", cancelledStatus, "neq");
    this.filterSortService.addFilter(this.kendoGridState, statusFilter);
  }

  private setOrderDateDecreasingSorting() {
    const orderDateSortDesc = this.filterSortService.createSortDescriptor('bkOrderDateKey', 'desc');
    this.filterSortService.setDefaultSortDescriptor(this.kendoGridState, orderDateSortDesc);
  }

  public setTitleValues(orders: OrderHeader[]) {
    let totalAmount = 0;
    let totalVat = 0;
    let totalAmountIncludingVat = 0;

    if (orders.length == 0) {
      this.totalValues.forEach(x => {
        x.value = "shared.notApplicable";
        x.subtitle = "shared.norecordsselected";
      });
      return;
    }
    else if (orders.length > 0) {
      this.totalValues.forEach(x => {
        x.subtitle = "shared.selectedrecords"
      });
    }

    orders.forEach(orderHeader => {
      if (orderHeader.TotalAmount) {
        totalAmount += orderHeader.TotalAmount;
      }
      if (orderHeader.TotalAmountIncludingVat) {
        totalAmountIncludingVat += orderHeader.TotalAmountIncludingVat;
      }
      if (orderHeader.TotalVat) {
        totalVat += orderHeader.TotalVat;
      }
    });

    if (this.totalValues.length > 0) {
      this.totalValues[0].value = Utils.FormatNumber(totalAmount, 2);
    }
    if (this.totalValues.length > 1) {
      this.totalValues[1].value = Utils.FormatNumber(totalVat, 2);
    }
    if (this.totalValues.length > 2) {
      this.totalValues[2].value = Utils.FormatNumber(totalAmountIncludingVat, 2);
    }

    this.translateTitleValues();
  }

  private translateTitleValues(): void {
    if (this.totalValues.length > 0) {
      this.totalValues[0].title = Utils.TryAddCurrencyCode(this.translateService.instant("order.TotalAmount"), this.currentCustomer);
    }
    if (this.totalValues.length > 1) {
      this.totalValues[1].title = Utils.TryAddCurrencyCode(this.translateService.instant("order.TotalVat"), this.currentCustomer);
    }
    if (this.totalValues.length > 2) {
      this.totalValues[2].title = Utils.TryAddCurrencyCode(this.translateService.instant("order.TotalAmountIncludingVat"), this.currentCustomer);
    }
  }

  private setTotalValues() {
    const totalValues: TitleValue[] = [
      {
        title: Utils.TryAddCurrencyCode(this.translateService.instant("order.TotalAmount"), this.currentCustomer),
        value: "shared.notApplicable",
        subtitle: "shared.norecordsselected",
        field: "TotalAmount"
      },
      {
        title: Utils.TryAddCurrencyCode(this.translateService.instant("order.TotalVat"), this.currentCustomer),
        value: "shared.notApplicable",
        subtitle: "shared.norecordsselected",
        field: "TotalVat"
      },
      {
        title: Utils.TryAddCurrencyCode(this.translateService.instant("order.TotalAmountIncludingVat"), this.currentCustomer),
        value: "shared.notApplicable",
        subtitle: "shared.norecordsselected",
        field: "TotalAmountIncludingVat"
      },
    ];

    this.totalValues = FormConfiguration.applyToTitleValues(this.formConfig, totalValues);
  }

  private setupLanguageChangeHandler() {
    this.translateService.onLangChange.pipe(takeUntil(this._destroying$)).subscribe(() => {
      this.translateTitleValues();
    })
  }
}
