@if (customerServiceDialogOpened) {
  <ntw-customer-service-dialog [customer]="this.currentCustomer"
  [close]="this.closeCustomerServiceDialog"></ntw-customer-service-dialog>
}
<ul class="navigation-menu-horizontal inline" [hidden]="!this.currentAccount">
  @for (option of options | navigationMenuOptionFilter; track option) {
    <li class="navigation-menu-item-horizontal"
      [routerLink]="option.navigateToFunction ? null : [this.getLink(option)]" routerLinkActive="active"
      [routerLinkActiveOptions]="{exact: true}"
      (click)="option.navigateToFunction ? option.navigateToFunction() : null">
      <a class="inline">
        <kendo-svg-icon class="inline" [icon]="getIcon(option)"></kendo-svg-icon>
        <span>{{ option.label | translate }}</span>
      </a>
    </li>
  }
</ul>
<kendo-dropdownbutton [data]="this.menuOptions" #menuOptions class="inline" [hidden]="isMenuButtonHidden()">
  <kendo-svg-icon [icon]="this.icons.menu"></kendo-svg-icon>
  <ng-template kendoDropDownButtonItemTemplate let-dataItem>
    <div class="m-2">
      <a class="inline" [routerLink]="dataItem.navigateToFunction ? null : [this.getLink(dataItem)]"
        (click)="dataItem.navigateToFunction ? dataItem.navigateToFunction() : null">
        <kendo-svg-icon class="inline" [icon]="getIcon(dataItem)"></kendo-svg-icon>
        <span>{{dataItem.label | translate }}</span>
      </a>
    </div>
  </ng-template>
</kendo-dropdownbutton>