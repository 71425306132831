@if (loading) {
  <kendo-loader [size]="loaderSize"></kendo-loader>
}
@if (!loading && !(disabled && showNgContentOnDisabled)) {
  <kendo-dropdownlist [disabled]="disabled" [defaultItem]="defaultItem" [data]="data"
    [textField]="textField" [valueField]="valueField" (valueChange)="valueChange.emit($event)">
  </kendo-dropdownlist>
}
@if (!loading && disabled && showNgContentOnDisabled) {
  <ng-content></ng-content>
}