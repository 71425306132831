import { Component, Input } from '@angular/core';
import { LoaderModule, LoaderSize } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'ntw-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss', './../../styles/button.scss'],
  imports: [LoaderModule]
})
export class LoadingButtonComponent {
  @Input() public buttonClass = "";
  @Input() public buttonStyle = "";
  @Input() public text = "placeholder";
  @Input() public loaderSize: LoaderSize = "small";
  @Input() public loading = false;
  @Input() public disabled = false;
}
