<kendo-dialog
  title="{{ 'alerts.maintenanceDialogTitle' | translate }}"
  (close)="!isMaintenanceActive ? close() : null"
  [minWidth]="350"
  [maxWidth]="800"
  [minHeight]="400"
  [maxHeight]="800"
  >
  <div class="container h-100">
    <div class="row d-none d-lg-flex h-100">
      <div class="col d-flex justify-content-center align-items-center h-100">
        <img src="assets/images/maintenance.png" class="img-fluid img-maintenance" alt="Image">
      </div>
      <div class="col scrollable-content">
        @if (scheduledStartTime || scheduledEndTime) {
          <div class="d-flex justify-content-center">
            <div class="alert alert-warning" role="alert">
              <p class="mb-0">
                <strong>Scheduled Maintenance</strong>
              </p>
              @if (scheduledStartTime) {
                <p class="mb-0">
                  <strong>Start Time:</strong> {{ scheduledStartTime | date: 'MMM d, y, HH:mm' }}
                </p>
              }
              @if (scheduledEndTime) {
                <p class="mb-0">
                  <strong>End Time:</strong> {{ scheduledEndTime | date: 'MMM d, y, HH:mm' }}
                </p>
              }
              @if (!scheduledEndTime) {
                <p class="mb-0">
                  <strong>End Time:</strong> To be determined
                </p>
              }
            </div>
          </div>
        }
        @if (!isMaintenanceActive && isAnnouncementActive) {
          <div
            [innerHTML]="announcementMessage">
          </div>
        }
        @if (isMaintenanceActive) {
          <div
            [innerHTML]="maintenanceMessage">
          </div>
        }
      </div>
    </div>
    <div class="row d-lg-none">
      <div class="col scrollable-content">
        <div class="d-flex justify-content-center">
          <img src="assets/images/maintenance.png" class="img-fluid img-maintenance" alt="Image">
        </div>
        @if (scheduledStartTime || scheduledEndTime) {
          <div class="d-flex justify-content-center">
            <div class="mt-2 alert alert-warning" role="alert">
              <p class="mb-0">
                <strong>Scheduled Maintenance</strong>
              </p>
              @if (scheduledStartTime) {
                <p class="mb-0">
                  <strong>Start Time:</strong> {{ scheduledStartTime | date: 'MMM d, y, HH:mm' }}
                </p>
              }
              @if (scheduledEndTime) {
                <p class="mb-0">
                  <strong>End Time:</strong> {{ scheduledEndTime | date: 'MMM d, y, HH:mm' }}
                </p>
              }
              @if (!scheduledEndTime) {
                <p class="mb-0">
                  <strong>End Time:</strong> To be determined
                </p>
              }
            </div>
          </div>
        }
        @if (!isMaintenanceActive && isAnnouncementActive) {
          <div
            [innerHTML]="announcementMessage">
          </div>
        }
        @if (isMaintenanceActive) {
          <div
            [innerHTML]="maintenanceMessage">
          </div>
        }
      </div>
    </div>
  </div>
  @if (!isMaintenanceActive) {
    <kendo-dialog-actions>
      <button class="k-button k-button-md k-rounded-md k-button-solid-base k-button-solid" kendoButton (click)="close()">{{ 'shared.close' | translate}}</button>
    </kendo-dialog-actions>
  }
</kendo-dialog>