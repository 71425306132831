import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { downloadLightIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'ntw-download-link',
  styles: [`
        :host {
            font-family: inherit;
        }
        a {
            color: #000;
        }
        .hidden {
            opacity: 0;
        }
        small {
            color: #6c757d;
            font-weight: 300;
        }
        .text-container {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    `],
  template: `
    <div class="container">
      <div class="row">
        <div class="col-auto">
          <a class="row gx-2 text-decoration-none" [href]="link" target="_blank">
            <div class="col-auto">
              <kendo-svg-icon [icon]="icons.download" size="large"></kendo-svg-icon>
            </div>
            <div class="col text-container">
              <span>{{text | translate}}</span>
            </div>
          </a>
        </div>
        <div class="col"></div>
      </div>
      @if (subText) {
        <div class="row gx-2">
          <div class="col-auto">
            <kendo-svg-icon [icon]="icons.download" size="large" class="hidden"></kendo-svg-icon>
          </div>
          <div class="col">
            <small>{{'(' + (subText | translate) + ')'}}</small>
          </div>
        </div>
      }
    </div>
    `,
  imports: [TranslateModule, SVGIconModule]
})

export class DownloadLinkComponent {
  @Input() public link: string;
  @Input() public text: string;
  @Input() public subText: string;

  public icons = {
    download: downloadLightIcon
  }
}
