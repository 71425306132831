import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DialogActionsComponent, DialogComponent } from '@progress/kendo-angular-dialog';
import { MaintenanceType } from 'src/app/shared/models/maintenance-configuration';
import { MaintenanceService } from 'src/app/shared/services/maintenance/maintenance.service';

@Component({
  selector: 'ntw-maintenance-dialog',
  templateUrl: './maintenance-dialog.component.html',
  styleUrls: ['./maintenance-dialog.component.scss'],
  imports: [DialogComponent, CommonModule, DialogActionsComponent, TranslateModule]
})
export class MaintenanceDialogComponent {
  @Input() public close: () => void;

  public scheduledStartTime: Date | null = null;
  public scheduledEndTime: Date | null = null;

  public maintenanceMessage: string;
  public announcementMessage: string;

  public isMaintenanceActive = false;
  public isAnnouncementActive = false;

  constructor(
    private maintenanceService: MaintenanceService
  ) {
    this.maintenanceService.maintenanceConfiguration.subscribe({
      next: maintenanceConfiguration => {
        if (maintenanceConfiguration) {
          this.maintenanceMessage = maintenanceConfiguration.maintenanceMessage;
          this.announcementMessage = maintenanceConfiguration.announcementMessage;
          if (maintenanceConfiguration.maintenanceType === MaintenanceType.Scheduled) {
            this.scheduledStartTime = maintenanceConfiguration.maintenanceStartTime;
            this.scheduledEndTime = maintenanceConfiguration.maintenanceEndTime;
          }
        }
      }
    });
    this.maintenanceService.isMaintenanceActive.subscribe({
      next: isMaintenanceActive => {
        this.isMaintenanceActive = isMaintenanceActive;
      }
    });
    this.maintenanceService.isAnnouncementActive.subscribe({
      next: isAnnouncementActive => {
        this.isAnnouncementActive = isAnnouncementActive;
      }
    });
  }
}
