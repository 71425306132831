<ntw-navigation-top-bar></ntw-navigation-top-bar>
@if (isMaintenanceDialogOpened) {
  <ntw-maintenance-dialog [close]="this.closeMaintenanceDialog"></ntw-maintenance-dialog>
}
@if (!isMaintenanceDialogOpened && !isMaintenanceActive) {
  <div class="container">
    <ntw-routing-breadcrumbs></ntw-routing-breadcrumbs>
    @if (loading) {
      <ntw-loading-panel></ntw-loading-panel>
    }
    @if (!isIframe && !loading) {
      <router-outlet></router-outlet>
    }
  </div>
}
