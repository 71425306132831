<div #domPortalContent class="parent" [hidden]="!this.opened">
  <kendo-window title="{{'orderProcess.products.chooseColorShade' | translate}}" [hidden]="!this.opened"
    (close)="closeWindow()" [width]="935" [height]="720" [resizable]="false" [(top)]="this.windowTop"
    [(left)]="this.windowLeft">
    <div class="row">
      <div class="col-6">
        <kendo-dropdownlist #family [defaultItem]="'shared.filterFamily' | translate"
          [data]="this.colorFamilies" [(ngModel)]="this.chosenFamily"
          (valueChange)="handlePaleteDropDownChange($event)">
        </kendo-dropdownlist>
      </div>
      <div class="col-6">
        <kendo-dropdownlist #range style="width: 19em;" [defaultItem]="'shared.filterRange' | translate"
          [data]="this.colorRanges" [(ngModel)]="this.chosenRange"
          (valueChange)="handlePaleteDropDownChange($event)">
        </kendo-dropdownlist>
      </div>
    </div>
    <div class="row pt-2">
      @for (shade of this.colorShadeList; track shade) {
        <div class="col-2" style="max-width: 70px;">
          <div class="row pt-1">
            <div class="col">
              <button type="button" look="clear" class="k-primary color-shade-button"
                [ngStyle]="{'background-color': '#'+ getHexColor(shade)}" (click)="pickColor(shade)">
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col">
              {{shade.Code}}
            </div>
          </div>
        </div>
      }
    </div>
  </kendo-window>
</div>
<div class="mt-3 item decima-mono-font" [ngClass]="{'item': !this.isDeleted, 'deletedItem': this.isDeleted}">
  <div class="d-none d-xl-block col-2 px-2">
    <span class="item-name">{{ this.dataItem.axCode }}</span>
  </div>
  <div class="col-3 px-2">
    <span class="item-name text-wrap">{{ this.dataItem.name }}</span>
  </div>
  @if (!this.isDeleted) {
    <div class="d-none d-md-block col"></div>
    <div class="d-none d-lg-block col-3 col-xl-2 px-2 me-2">
      <div class="row">
        <div class="col-5 col-xl-6 pe-1">
          <span class="label">{{"orderProcess.products.PalletSizeName" | translate}}: </span>
        </div>
        <div class="col ps-1">
          <span class="item-name">{{ this.dataItem.palletSizeName }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-5 col-xl-6 pe-1">
          <span class="label">{{"orderProcess.products.GrossWeightKG" | translate}}: </span>
        </div>
        <div class="col ps-1">
          <span class="item-name">{{ this.dataItem.grossWeightKG }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-5 col-xl-6 pe-1">
          <span class="label">{{"orderProcess.products.VolumeL" | translate}}: </span>
        </div>
        <div class="col ps-1">
          <span class="item-name">{{ this.dataItem.volumeL }}</span>
        </div>
      </div>
    </div>
    <div class="col-auto">
      <div class="row">
        <div class="col">
          <kendo-floatinglabel class="quantity pb-2" text="{{'orderProcess.products.quantity' | translate}}">
            <kendo-numerictextbox [min]='1' [max]="999999" [decimals]="0" [autoCorrect]="true" [step]="1" [format]="'n0'" [(ngModel)]="this.dataItem.quantity"
            (ngModelChange)="this.ValuesChanged(this.dataItem.quantity)"></kendo-numerictextbox>
          </kendo-floatinglabel>
        </div>
        <div class="col d-inline-flex flex-column px-3">
          @if (inventory) {
            <kendo-label class="inventory"
              text="{{'orderProcess.products.inventory' | translate}}">
            </kendo-label>
          }
          @if (isInventoryLoading()) {
            <kendo-loader size="medium" class="inventoryLoader"></kendo-loader>
          }
          @if (isInventoryLoaded()) {
            <span class="item-name py-2">
            {{this.inventory.availQtyInSalesUnit >= 0 ? this.inventory.availQtyInSalesUnit : 0}}</span>
          }
          @if (isInventoryNotAvailable()) {
            <span class="item-name px-1 py-2">NA</span>
          }
        </div>
      </div>
    </div>
    <div class="d-none d-md-block col"></div>
    @if (!env.production && !this.shadesLoading) {
      <div #colorShadeChooser class="col-auto col-xxl-2 px-3"
        [title]="this.paletteTooltipText">
        <kendo-autocomplete class="w-100"
          [disabled]="!this.isTintingProduct(this.dataItem) || !this.productHasEanCode(this.dataItem) || this.dataItem.colorShadeRetrieveError"
          [value]='this.dataItem.chosenColorShade?.Code' [data]="this.colorShadeList" [filterable]="true"
          (filterChange)="handleColorPickerFilter($event)" [placeholder]="'e.g. TVT'" [valueField]="'Code'"
          (valueChange)="onColorChanged($event)">
        </kendo-autocomplete>
        <div class="color-palette">
          <button
            [disabled]="!this.isTintingProduct(this.dataItem) || !this.productHasEanCode(this.dataItem) || this.dataItem.colorShadeRetrieveError"
            type="button" kendoButton [svgIcon]="this.icons.palette" class="k-button color-pallette" look="clear"
          (click)="openWindow()">{{"orderProcess.products.palette" | translate}}</button>
        </div>
      </div>
    }
    <div class="col"></div>
    @if (!env.production && this.shadesLoading) {
      <div #colorShadeLoader class="col-2 px-3">
        <kendo-loader type="pulsing" size="large" class="ms-3 colorShadeLoader"></kendo-loader>
      </div>
    }
    <div class="col-auto me-1">
      <button class="command-button col p-3 float-end" kendoButton look="flat"
        (click)="this.OnDeleteClicked(this.dataItem)">
        <kendo-svg-icon [icon]="this.icons.trash" class="button-icon"></kendo-svg-icon>
      </button>
      <button class="command-button col p-3 float-end" kendoButton look="flat"
        [disabled]="!this.isTintingProduct(this.dataItem) || !this.productHasEanCode(this.dataItem) || this.dataItem.colorShadeRetrieveError"
        (click)="this.OnCopyClicked(this.dataItem)">
        <kendo-svg-icon [icon]="this.icons.copy" class="button-icon"></kendo-svg-icon>
      </button>
    </div>
  }
  @if (this.isDeleted) {
    <div class="col d-flex justify-content-center">
      <span class="item-name pe-1">{{"orderProcess.products.productRemoved" | translate}}</span>
      <a class="text-decoration-none undoButton" (click)="this.OnUndoClicked(this.dataItem)">
        <span class="item-name">{{"orderProcess.products.undo" | translate}}</span>
      </a>
    </div>
  }
</div>