import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { TextBoxComponent, TextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { infoCircleIcon } from '@progress/kendo-svg-icons';
import { LoadingPanelComponent } from 'src/app/shared/components/loading-panel';
import { CompanyCode } from 'src/app/shared/constants/company-code';
import { CrmAccount } from 'src/app/shared/models/account';
import { NewOrderAddress } from 'src/app/shared/models/address/address';
import { PortalOrder } from 'src/app/shared/models/order-creation/portal-order';
import { SecurityService } from 'src/app/shared/security/security.service';
import { KendoAlertService } from 'src/app/shared/services/kendo-alerts.service';
import { IOrderProcessService } from '../services/order.process.service.interface';

@Component({
    templateUrl: './select-details.component.html',
    selector: 'ntw-order-select-details',
    styleUrls: ['./select-details.component.scss'],
    imports: [TranslateModule, SVGIconModule, LoadingPanelComponent, CommonModule, LabelModule, TextBoxModule, FormsModule]
})

export class SelectDetailsComponent implements OnInit {
    @Input() portalOrder: PortalOrder
    @Output() portalOrderChange = new EventEmitter<PortalOrder>();

    @Input() isDetailsStepValid: boolean;
    @Output() isDetailsStepValidChange = new EventEmitter<boolean>();

    @Input() missingOrderFields: EventEmitter<void>;
    @ViewChild('customerReference') customerReferenceTextBox: TextBoxComponent;
    @ViewChild('customerRequisition') customerRequisitionTextBox: TextBoxComponent;

    loading = true;
    isCompanyNorway = false;
    activeFilterQuery: string;
    deliveryAddresses: NewOrderAddress[] = [];
    currentAccount: CrmAccount;

    public icons = {
        info: infoCircleIcon
    }

    constructor(private kendoAlertService: KendoAlertService, private orderProcessService: IOrderProcessService, private securityService: SecurityService) { }

    ngOnInit(): void {
        this.currentAccount = this.securityService.getCurrentAccountObject();
        this.isCompanyNorway = this.portalOrder?.bkCompanyNumber === CompanyCode.Norway;
        if (!this.isCompanyNorway) {
            this.getAddresses();
        }
        else {
            this.loading = false;
        }

        // Focus and blur to mark component as touched resulting in validation error to highlight the field
        this.missingOrderFields.subscribe(() => {
            if (!this.portalOrder.customerRequisition) {
                this.customerRequisitionTextBox.focus();
                this.customerRequisitionTextBox.blur();
            }
            if (!this.portalOrder.customerReference) {
                this.customerReferenceTextBox.focus();
                this.customerReferenceTextBox.blur();
            }
            if (!this.portalOrder.deliveryAddress) {
                this.getAddresses();
            }
        });
    }

    public validate(): void {
        if (this.portalOrder.customerRequisition && this.portalOrder.customerReference) {
            this.isDetailsStepValidChange.emit(true);
        }
        else {
            this.isDetailsStepValidChange.emit(false);
        }
        this.portalOrderChange.emit(this.portalOrder);
    }

    public getAddresses(): void {
        this.loading = true;
        this.orderProcessService.getAddresses(this.currentAccount?.ntw_axcode)
            .subscribe({
                next: data => {
                    this.deliveryAddresses = this.adjustAddresses(data);
                    if (this.deliveryAddresses.length === 0) {
                        this.kendoAlertService.showErrorAlert("orderProcess.noDeliveryAddressesFound");
                    }
                    const defaultAddress = this.deliveryAddresses.find(x => x.isDefaultDelivery === 1);
                    this.portalOrder.deliveryAddress = defaultAddress ?? this.deliveryAddresses[0];
                    this.loading = false;
                }, error: error => {
                    this.kendoAlertService.showErrorAlert(error);
                    this.loading = false;
                }
            });
    }

    private adjustAddresses(addresses: NewOrderAddress[]): NewOrderAddress[] {
        if (!addresses) {
            return null;
        }

        addresses = addresses.filter(x => x.street && x.city && x.countryRegion && x?.isRoleDelivery === 1);
        addresses.forEach(x => x.street = x.street?.replace("\\n", " "));
        addresses.forEach(x => x.name = this.currentAccount?.name);
        return addresses;
    }
}
