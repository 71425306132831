<div class="container">
  <div class="row row-cols-2 gx-5">
    <div class="col decima-mono-font">
      <div class="row row-cols-2 pt-3">
        <div class="col">
          <kendo-label class="label label-star" [for]="customerRequisition"
            text="{{'orderProcess.details.customerRequisition' | translate}}">
          </kendo-label>
        </div>
        <div class="col">
          <kendo-textbox #customerRequisition [(ngModel)]="this.portalOrder.customerRequisition"
            (ngModelChange)="validate()"
            [showSuccessIcon]="this.portalOrder.customerRequisition?.length > 0" class="h-100"
            [maxlength]="255" showErrorIcon="initial" required>
          </kendo-textbox>
        </div>
      </div>
      <div class="row row-cols-2 pt-3">
        <div class="col">
          <kendo-label class="label label-star" [for]="customerReference"
            text="{{'orderProcess.details.customerReference' | translate}}">
          </kendo-label>
        </div>
        <div class="col">
          <kendo-textbox #customerReference [(ngModel)]="this.portalOrder.customerReference"
            (ngModelChange)="validate()" [showSuccessIcon]="this.portalOrder.customerReference?.length > 0"
            class="h-100" [maxlength]="60" showErrorIcon="initial" required>
          </kendo-textbox>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="row pt-3">
        <div class="col">
          <kendo-label class="label" text="{{'orderProcess.details.deliveryInformation' | translate}}">
            <kendo-svg-icon class="label-info" [icon]="this.icons.info"></kendo-svg-icon>
          </kendo-label>
        </div>
      </div>
      @if (this.portalOrder.deliveryAddress && !this.loading) {
        <div class="row row-cols-2 pt-3">
          <div class="col">
            <kendo-label class="label" [for]="deliveryAddress"
              text="{{'orderProcess.deliveryAddress' | translate}}">
            </kendo-label>
          </div>
          <div #deliveryAddress class="col decima-mono-font">
            <div>{{ this.portalOrder.deliveryAddress.street }}</div>
            <div>{{ this.portalOrder.deliveryAddress.zipCode }}</div>
            <div>{{ this.portalOrder.deliveryAddress.city }}, {{this.portalOrder.deliveryAddress.countryRegion
              }}
            </div>
          </div>
        </div>
      }
      @if (!this.portalOrder.deliveryAddress || this.loading) {
        <div class="row row-cols-2 pt-3">
          <div class="col">
            <kendo-label class="label" text="{{'orderProcess.deliveryAddress' | translate}}">
            </kendo-label>
          </div>
                <div [ngClass]="{
                    'col-3': this.loading,
                    'col': !this.loading
                }">
            @if (this.loading) {
              <ntw-loading-panel [fullscreen]="false" size="medium" type="pulsing"
              themeColor="primary"></ntw-loading-panel>
            }
          </div>
        </div>
      }
    </div>
  </div>
</div>