import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationTopBarComponent } from './navigation-top-bar/navigation-top-bar.component';
import { AngularSvgIconModule, SvgHttpLoader, SvgLoader } from 'angular-svg-icon';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { UserOptionChooserComponent } from './user-option-chooser/user-option-chooser.component';
import { NavigationMenuComponent } from './navigation-menu/navigation-menu.component';
import { NavigationMenuOptionFilterPipe } from "./navigation-menu/navigation-menu-option-filter-pipe";
import { AccountContextChooserComponent } from './account-context-chooser/account-context-chooser.component';


@NgModule({
    imports: [
        CommonModule,
        AngularSvgIconModule.forRoot({
            loader: {
                provide: SvgLoader,
                useFactory: HttpSvgLoaderFactory,
                deps: [HttpBackend]
            }
        }),
        NavigationTopBarComponent, AccountContextChooserComponent, UserOptionChooserComponent, NavigationMenuComponent, NavigationMenuOptionFilterPipe
    ],
    exports: [
        NavigationTopBarComponent,
        AccountContextChooserComponent,
        NavigationMenuOptionFilterPipe
    ]
})
export class NavigationTopBarModule { }

export function HttpSvgLoaderFactory(handler: HttpBackend) {

  const http = new HttpClient(handler);
  return new SvgHttpLoader(http);
}
