import { AnimationEvent, animate, query, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DialogActionsComponent, DialogComponent } from '@progress/kendo-angular-dialog';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { chevronDownIcon } from '@progress/kendo-svg-icons';
import { CompanyCode } from '../../constants/company-code';
import { ProductWithInventoryAndQuantity } from '../../models/order-creation/product-with-quantity';
import { PIMProduct, PIMProductAsset } from '../../models/pimproducts/pimproduct';
import { TitleValue } from '../../models/ui/title-value';
import { PimproductsService } from '../../services/pimproducts/pimproducts.service';
import { DownloadLinkComponent } from '../download-link';
import { LoadingPanelComponent } from '../loading-panel';
import { TitleValueComponent } from '../title-value.component';

@Component({
  selector: 'ntw-product-detail-dialog',
  templateUrl: './product-detail-dialog.component.html',
  styleUrls: ['./product-detail-dialog.component.scss'],
  animations: [
    trigger('collapse', [
      state('open', style({
        display: '*',
        height: '*',
      })),
      state('closed', style({
        display: 'none',
        height: '0',
      })),
      transition('open => closed', [
        // add overflow clip to prevent content from overflowing during animation
        style({ overflow: 'clip' }),
        // animate the wrapper element height to 0
        animate('0.5s ease', style({ height: '0', opacity: 0 })),
      ]),
      transition('closed => open', [
        // show the wrapper element
        style({ display: '*', height: '*', overflow: 'clip', opacity: 1 }),
        // animate the wrapper height to its final value
        query('.wrapper', [
          style({ display: '*', height: '0', overflow: 'clip', opacity: 0 }),
          animate('0.5s ease', style({ height: '*', opacity: 1 })),
        ]),
      ]),
    ]),
    trigger('rotate', [
      state('collapsed', style({
        transform: 'rotate(180deg)',
      })),
      state('expanded', style({
        transform: 'rotate(0deg)',
      })),
      transition('collapsed <=> expanded', [
        animate('0.25s ease')
      ]),
    ]),
  ],
  imports: [LoadingPanelComponent, DialogComponent, TranslateModule, CommonModule, TitleValueComponent, DownloadLinkComponent, SVGIconModule,
    DialogActionsComponent
  ]
})
export class ProductDetailDialogComponent implements OnInit, OnDestroy {
  @Input() public close: () => void;

  @Input() public product: ProductWithInventoryAndQuantity;
  @Input() public companyNumber: string;

  @ViewChild('container') public container: HTMLDivElement;
  @ViewChild('mainImage') public mainImage: HTMLImageElement;
  @ViewChild('mainImageRow') public mainImageRow: HTMLDivElement;

  public dialogSize = '80%';
  public maxDialogHeight = '600px';

  public pimProduct: PIMProduct;
  public pimProductImageUrl: string;
  public pimProductDataSheets: PIMProductAsset[];
  public pimEnvironmentalDataSheets: PIMProductAsset[];
  public pimDeclarationsOfPerformance: PIMProductAsset[];
  public pimProductEcoLabels: string[] = [];
  public maxEcoLabelWidth = '100%';
  public maxMainImageWidth = '100%';
  public mainImageOpacity = 0;
  public get pimProductLoaded(): boolean {
    return !!this.pimProduct && this.pimProduct.mediaRelation?.length > 0;
  }
  public get hasEcoLabels(): boolean {
    return !!this.pimProductEcoLabels && this.pimProductEcoLabels.length > 0;
  }

  public pimFirstRow: TitleValue[] = [];
  public pimSecondRow: TitleValue[] = [];
  public pimThirdRow: TitleValue[] = [];

  public isFirstRender = true;
  public isDataSheetsCollapsed = false;

  public loading = true;
  public firstRow: TitleValue[] = [];
  public secondRow: TitleValue[] = [];
  public thirdRow: TitleValue[] = [];

  public icons = {
    chevronDown: chevronDownIcon,
  }

  public get language(): string {
    switch (this.companyNumber) {
      case CompanyCode.Sweden:
        return 'Swedish';
      case CompanyCode.Norway:
        return 'Norwegian';
      default:
        return 'English';
    }
  }


  constructor(
    private pimProductsService: PimproductsService,
    private renderer: Renderer2,
    private breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'no-scroll');
    this.setupBreakpointObserver();
    this.fillRowsWithData();
    this.pimProductsService.get(this.product.AXCode).subscribe({
      next: (product) => {
        this.pimProduct = product;
        if (this.pimProductLoaded) {
          this.fillPimFields();
          this.fillPimRowsWithData();
        }
        this.loading = false;
      },
      error: error => {
        console.error('Error while getting PIM product', error);
        this.loading = false;
      }
    });

    window.onresize = () => {
      if (window.innerWidth < 992) {
        this.maxDialogHeight = '100%';
      }
      else {
        this.maxDialogHeight = '600px';
      }
      if (this.mainImage && this.mainImageRow && this.container) {
        this.setMainImageSize(this.mainImage['nativeElement'], this.mainImageRow['nativeElement'], this.container['nativeElement']);
      }
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'no-scroll');

    window.onresize = null;
  }

  public onCollapseAnimationStart($event: AnimationEvent, scrollTarget: HTMLElement) {
    if (this.isFirstRender) {
      this.isFirstRender = false;
    }
    else if (!this.isFirstRender && $event.toState === 'open') {
      scrollTarget.scrollIntoView({ behavior: 'smooth' });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onImageError(event: any, hideImage: boolean = false) {
    if (hideImage) {
      event.target.style.display = 'none';
      const ecoLabel = this.pimProductEcoLabels.find(label => label === event.target.title);
      if (ecoLabel) {
        this.pimProductEcoLabels = this.pimProductEcoLabels.filter(label => label !== ecoLabel);
        this.updateImageWidths();
      }
      return;
    }

    event.target.src = 'assets/images/noimage.png';
  }

  public setMainImageSize(image: HTMLImageElement, row: HTMLDivElement, container: HTMLDivElement) {
    if (!image || !row || !container) {
      return;
    }

    const aspectRatio = image.naturalWidth / image.naturalHeight;

    const ecoLabelMultiplier = this.hasEcoLabels ? 0.8 : 1;
    const minus = this.dialogSize !== '100%' ? 60 : 20;
    this.maxMainImageWidth = Math.min(container.clientHeight * ecoLabelMultiplier * aspectRatio, container.clientWidth, row.clientWidth) - minus + 'px';
    this.mainImageOpacity = 1;
  }

  public removeSpaces(text: string): string {
    return text.replace(/\s/g, '');
  }

  private fillPimFields() {
    this.setMediaRelationFields();
    this.setEcoLabelFields();
  }

  private setMediaRelationFields() {
    const assets = this.pimProduct.mediaRelation.sort((a, b) => parseInt(b.fileId) - parseInt(a.fileId)); // sort by fileId descending
    this.pimProductImageUrl = this.pimProduct.mainImageURL ?? assets.find(asset => asset.type === 'Can Image')?.staticUrl;

    const companyLanguageAssets = assets.filter(asset => asset.type !== "Can Image" && (!asset.languages || asset.languages.includes(this.language)));
    companyLanguageAssets.forEach(asset => {
      if (!asset.name || asset.name.replace(/\s/g, '') === '') {
        asset.name = asset.filename;
      }
      asset.typeTranslationCode = 'product.dataSheetTypes.' + asset.type.toLowerCase().replace(/\s/g, '');
    });
    this.pimProductDataSheets = companyLanguageAssets;
  }

  private setEcoLabelFields() {
    this.pimProductEcoLabels = this.pimProduct.ecolabelsRecommendations?.split('|').filter(label => label.trim() !== '');

    this.updateImageWidths();
  }

  private updateImageWidths(): void {
    const maxWidth = this.hasEcoLabels ? 100 / this.pimProductEcoLabels.length : 100;
    const maxMargin = this.hasEcoLabels ? 1 / this.pimProductEcoLabels.length : 1;
    this.maxEcoLabelWidth = 'min(120px, calc(' + maxWidth + '% - ' + maxMargin + 'rem))';
  }

  private setupBreakpointObserver() {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
    ]).subscribe(result => {
      if (result.matches) {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.dialogSize = '100%';
        }
        else if (result.breakpoints[Breakpoints.Small] || result.breakpoints[Breakpoints.Medium]) {
          this.dialogSize = '90%';
        }
      }
      else {
        this.dialogSize = '80%';
      }
    });
  }

  private fillPimRowsWithData() {
    this.pimFirstRow = [
      {
        title: "product.Name",
        value: this.product.Name,
        subtitle: ''
      }
    ];
    this.pimSecondRow = [
      {
        title: "product.AXCode",
        value: this.product.AXCode,
        subtitle: ''
      },
      {
        title: "product.EANCode",
        value: this.product.EANCode,
        subtitle: ''
      },
    ];
    this.pimThirdRow = [
      {
        title: "product.VolumeL",
        value: this.product.VolumeL,
        subtitle: ''
      },
      {
        title: "product.BaseName",
        value: this.product.BaseName,
        subtitle: ''
      }
    ];
  }

  public fillRowsWithData() {
    this.firstRow = [
      {
        title: "product.Name",
        value: this.product.Name,
        subtitle: ''
      },
      {
        title: "product.BaseName",
        value: this.product.BaseName,
        subtitle: ''
      },
      {
        title: "product.AXCode",
        value: this.product.AXCode,
        subtitle: ''
      },
      {
        title: "product.EANCode",
        value: this.product.EANCode,
        subtitle: ''
      },
    ];

    this.secondRow = [
      {
        title: "product.VolumeL",
        value: this.product.VolumeL,
        subtitle: ''
      },
      {
        title: "product.GrossWeightKG",
        value: this.product.GrossWeightKG,
        subtitle: ''
      },
      {
        title: "product.NetWeightKG",
        value: this.product.NetWeightKG,
        subtitle: ''
      },
      {
        title: "product.Density",
        value: this.product.Density,
        subtitle: ''
      },
    ]

    this.thirdRow = [
      {
        title: "product.ADRClassName",
        value: this.product.ADRClassName,
        subtitle: ''
      },
      {
        title: "product.BrandName",
        value: this.product.BrandName,
        subtitle: ''
      },
      {
        title: "product.PalletSizeName",
        value: this.product.PalletSizeName,
        subtitle: ''
      }
    ]
  }
}
