import { DomPortal, Portal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { AutoCompleteModule, DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { NumericTextBoxModule } from '@progress/kendo-angular-inputs';
import { FloatingLabelModule, LabelModule } from '@progress/kendo-angular-label';
import { copyIcon, paletteIcon, trashIcon } from '@progress/kendo-svg-icons';
import { environment } from 'src/environments/environment';
import { StringConstants } from '../../constants/string-constants';
import { ColorShade, ColorShadeValue } from '../../models/colorshade/colorshade-value';
import { PortalOrderProduct } from '../../models/order-creation/portal-order-product';
import { InventoryOnline, InventoryOnlineStatus } from '../../models/products/inventory';
import { IColorshadeService } from '../../services/colorshade/colorshade.service.interface';
import { KendoAlertService } from '../../services/kendo-alerts.service';
import { StorageService } from '../../services/storage/storage-service';

@Component({
    selector: 'ntw-product-list-item',
    templateUrl: './product-list-item.html',
    styleUrls: ['./product-list-item.scss'],
    imports: [DropDownListComponent, TranslateModule, CommonModule, FormsModule, WindowModule, FloatingLabelModule, NumericTextBoxModule,
        LabelModule, LoaderModule, AutoCompleteModule, SVGIconModule, ButtonModule
    ]
})


export class ProductListItemComponent implements OnInit {
    @Input() public dataItem: PortalOrderProduct;
    @Input() public isDeleted: boolean;
    @Input() public inventory: InventoryOnline;
    @Input() public OnCopyClicked: (item) => void;
    @Input() public OnDeleteClicked: (item) => void;
    @Input() public OnUndoClicked: (item) => void;
    @Input() public ValuesChanged: (item) => void;

    @Input() public kendoWindowPortal: Portal<unknown>;
    @Output() public kendoWindowPortalChange: EventEmitter<Portal<unknown>> = new EventEmitter<Portal<unknown>>();

    env = environment;

    private allColorShades: ColorShade[]
    colorShadeList: ColorShade[];

    public windowTop = 160;
    public windowLeft = 100;
    public opened = false;
    public currentColorShadeCode = "";
    public paletteTooltipText = "tooltips.enabledPalette";
    @ViewChild('domPortalContent') domPortalContent: ElementRef<HTMLElement>;

    domPortal: DomPortal<unknown>;

    @ViewChild('range') rangeDropDown: DropDownListComponent;
    @ViewChild('family') familyDropDown: DropDownListComponent;

    public colorFamilies: string[];
    public chosenFamily: string;
    public colorRanges: string[];
    public chosenRange: string;

    public windowWidth: number;
    public windowHeight: number;
    public shadesLoading = true;

    public icons = {
        copy: copyIcon,
        palette: paletteIcon,
        trash: trashIcon
    }

    @HostListener('window:resize', ['$event'])
    onResize($event) {
        this.windowWidth = $event.target.innerWidth;
        const widthLeft = (this.windowWidth - 935) / 2;
        this.windowLeft = widthLeft;

        this.windowHeight = $event.target.innerHeight;
        const windowTop = (this.windowHeight - 720) / 2;
        this.windowTop = windowTop;
    }

    constructor(private colorShadeService: IColorshadeService,
        private translateService: TranslateService,
        private kendoAlertService: KendoAlertService,
        private storageService: StorageService<ColorShadeValue>) {
    }

    ngOnInit(): void {
        this.currentColorShadeCode = this.dataItem?.chosenColorShade?.Code;
        this.paletteTooltipText = this.translateService.instant("tooltips.palette.enabled");
        this.windowLeft = (window.innerWidth - 935) / 2;
        this.windowTop = (window.innerHeight - 720) / 2;
        this.getColorShades();
        this.verifyColorPickerEnabled();
    }

    public isInventoryLoading() {
        return this.inventory.status === InventoryOnlineStatus.Loading;
    }

    public isInventoryLoaded() {
        return this.inventory.status === InventoryOnlineStatus.Loaded;
    }

    public isInventoryNotAvailable() {
        return !this.isInventoryLoading() && !this.isInventoryLoaded();
    }

    public isTintingProduct(product: PortalOrderProduct): boolean {
        return !this.env.production && product.axProductDimensionGroupCode == StringConstants.TintableAXProductDimensionGroupCode;
    }

    public productHasEanCode(product: PortalOrderProduct): boolean {
        return product.eanCode !== null;
    }

    public verifyColorPickerEnabled(): void {
        const isTinting = this.isTintingProduct(this.dataItem);
        const hasEanCode = this.productHasEanCode(this.dataItem);

        if (isTinting && hasEanCode) {
            this.paletteTooltipText = this.translateService.instant("tooltips.palette.enabled")
        }
        else if (isTinting && !hasEanCode) {
            this.paletteTooltipText = this.translateService.instant("tooltips.palette.disabledEan");
        }
        else {
            this.paletteTooltipText = this.translateService.instant("tooltips.palette.disabledNotTinting")
        }
    }

    public handlePaleteDropDownChange(chosenValue: string) {
        if (!chosenValue) {
            return;
        }
        let colorShades = this.allColorShades.slice();
        if (this.chosenFamily && this.chosenFamily != this.familyDropDown.defaultItem) {
            colorShades = colorShades.filter(x => x.Families && x.Families.length > 0 && x?.Families[0] == this.chosenFamily);
        }

        if (this.chosenRange && this.chosenRange != this.rangeDropDown.defaultItem) {
            colorShades = colorShades.filter(x => x.Ranges && x.Ranges.length > 0 && x?.Ranges[0] == this.chosenRange);
        }
        this.colorShadeList = colorShades.slice();
    }

    public handleColorPickerFilter(textFilter: string): void {
        this.colorShadeList = this.allColorShades.filter(
            (s) => s?.Code.toLowerCase().indexOf(textFilter.toLowerCase()) !== -1
        );
    }

    public closeWindow() {
        this.opened = false;
        this.resetDropDownFilters();
    }

    public openWindow() {
        this.opened = true;
        this.renderWindowInParentComponent();
    }

    public pickColor(shade: ColorShade) {
        this.dataItem.chosenColorShade = shade;
        this.ValuesChanged(this.dataItem.chosenColorShade);
        this.closeWindow();
    }

    public getHexColor(shade: ColorShade) {
        return (+shade.RGBValue).toString(16);
    }

    public onColorChanged($colorCode: string) {
        const colorShadeByCode = this.allColorShades.find(x => x.Code == $colorCode);
        this.dataItem.chosenColorShade = colorShadeByCode ? colorShadeByCode : null;
        this.ValuesChanged(colorShadeByCode);
    }

    private getColorShades(): void {
        if (!this.isTintingProduct(this.dataItem)) {
            this.shadesLoading = false;
            return;
        }
        if (!this.productHasEanCode(this.dataItem)) {
            this.handleColorRetrievalError("alerts.errorProductHasNoEanCode");
            return;
        }
        const storedShadeItem = this.storageService.GetFromStorageByKey(StringConstants.LatestColorShadeStorage);

        if (storedShadeItem != null && storedShadeItem.EAN?.toLowerCase() == this.dataItem.eanCode.toLowerCase()) {
            this.handleColorShadeResponse(storedShadeItem);
            return;
        }

        this.shadesLoading = true;
        this.colorShadeService.getColorShadesByEanCode(this.dataItem.eanCode).subscribe({
            next: colorshade => {
                if (!colorshade || !colorshade.value || !colorshade.value[0] || typeof (colorshade.value) == "string") {
                    this.handleColorRetrievalError("alerts.errorColorShadesForProduct");
                    return;
                }
                colorshade.value[0].Shades = colorshade.value[0].Shades.sort((x, y) => x.Code > y.Code ? 1 : -1)
                const shadeItem = colorshade.value[0];
                shadeItem.EAN = this.dataItem.eanCode;
                this.storageService.SaveToStorage(StringConstants.LatestColorShadeStorage, shadeItem);
                this.handleColorShadeResponse(shadeItem);
            },
            error: error => {
                console.error(error);
                this.handleColorRetrievalError("alerts.errorColorShadesForProduct");
            }
        });
    }

    private handleColorShadeResponse(shadeItem: ColorShadeValue) {
        this.shadesLoading = false;
        this.allColorShades = shadeItem.Shades;
        this.colorShadeList = this.allColorShades.slice();
        this.initializeDropdowns(shadeItem);
    }

    private handleColorRetrievalError(errorAlert: string) {
        const alert = this.translateService.instant(errorAlert) + this.dataItem.name;
        this.kendoAlertService.showWarningAlert(alert, true);
        this.paletteTooltipText = this.translateService.instant("tooltips.palette.disabledNoColors");
        this.dataItem.colorShadeRetrieveError = true;
        this.shadesLoading = false;
    }

    private initializeDropdowns(shadeItem: ColorShadeValue) {
        const tempFamilies: string[] = [];
        const tempRanges: string[] = [];

        shadeItem.Families.forEach(element => {
            tempFamilies.push(element.Value);
        });
        shadeItem.Ranges.forEach(element => {
            tempRanges.push(element.Key);
        });
        this.colorFamilies = tempFamilies.slice();
        this.colorRanges = tempRanges.slice();
    }

    private resetDropDownFilters() {
        this.colorShadeList = this.allColorShades.slice();
        this.chosenFamily = this.familyDropDown.defaultItem;
        this.chosenRange = this.rangeDropDown.defaultItem;
    }

    private renderWindowInParentComponent() {
        this.kendoWindowPortal = this.domPortal;
        this.domPortal = new DomPortal(this.domPortalContent);
        this.kendoWindowPortalChange.emit(this.domPortal);
    }
}

