@if (productDetailDialogOpened) {
  <ntw-product-detail-dialog [close]="this.closeProductDetailDialog"
    [product]="this.productDetailDialogProduct" [companyNumber]="this.portalOrder.bkCompanyNumber">
  </ntw-product-detail-dialog>
}
<div class="row pt-2" [ngClass]="{'sticky-title-row': viewType === 'card'}">
  <div class="col mb-2 d-flex border-bottom">
    <div class="title">
      {{"orderProcess.products.selectProductsCart" | translate}}
    </div>
    <kendo-buttongroup class="pb-2" selection="single">
      <button kendoButton [toggleable]="true" [svgIcon]="this.icons.cardView" [style.height.px]="30"
        [selected]="this.viewType === 'card'" (click)="this.viewTypeChange('card')">
        <span class="d-none d-sm-inline">{{ 'orderProcess.products.cardView' | translate }}</span>
      </button>
      <button kendoButton [toggleable]="true" [svgIcon]="this.icons.listView" [style.height.px]="30"
        [selected]="this.viewType === 'list'" (click)="this.viewTypeChange('list')">
        <span class="d-none d-sm-inline">{{ 'orderProcess.products.listView' | translate }}</span>
      </button>
    </kendo-buttongroup>
  </div>
</div>
<div class="row gy-2 pb-2" [ngClass]="{'sticky-search-row': viewType === 'card'}">
  <div class="col-2 col-sm-1 col-lg-4">
    <ntw-sku-filter [disabled]="loading" (filterChanged)="onSkuFilterChanged($event)"></ntw-sku-filter>
  </div>
  <div class="col-10 col-sm-11 col-lg-4">
    <kendo-textbox ntwExtended placeholder="{{'shared.filterItems' | translate}}" [clearButton]="true" #searchBox
      [value]="this.searchValue" (afterValueChanged)="onAfterValueChanged(searchBox.value)">
      <ng-template kendoTextBoxPrefixTemplate>
        <kendo-svg-icon class="border-end" [icon]="this.icons.search"></kendo-svg-icon>
      </ng-template>
    </kendo-textbox>
  </div>
  @if (viewType === 'list') {
    <div class="col-12 col-lg-3 offset-lg-1">
      <button kendoButton [svgIcon]="this.icons.cart" class="primaryButton"
        [disabled]="!isAnyProductSelected() || isAnyQuantityValueInvalid()" (click)="onUpdateCartClick()">
        {{ 'orderProcess.products.updateCart' | translate }}
      </button>
    </div>
  }
</div>
@if (viewType === 'card') {
  @if (loading) {
    <ntw-loading-panel [fullscreen]="false" themeColor="primary"></ntw-loading-panel>
  }
  @if (!loading) {
    <div class="row gy-4 pb-4 mb-5">
      @for (product of entities; track product) {
        <div class="col-6 col-md-4 col-xl-3 d-flex justify-content-center">
          <ntw-product-card [product]="product" (addToCart)="onAddProductToCartClick($event)"
            (viewDetails)="onViewDetailsClick($event)">
          </ntw-product-card>
        </div>
      }
    </div>
  }
  <div class="container fixed-bottom-pager-container">
    <div class="row">
      <kendo-datapager [pageSizeValues]="pageSizes" [pageSize]="this.kendoGridState.take"
        [skip]="this.kendoGridState.skip" [total]="totalRecords"
        (pageChange)="productCardPagerPageChange($event)">
      </kendo-datapager>
    </div>
  </div>
}
@if (viewType === 'list') {
  <ntw-grid-template class="pt-2" gridTitle="products.title"
    [currentAccount]="this.currentAccount" [columnSettings]="this.columnSettings" idFieldName="AXCode"
    [entities]="this.entities" [(selectedEntity)]="this.selectedProduct" [selectableSettings]="this.selectableSettings"
    [dataGridView]="this.dataGridView" [summaries]="this.summaries" [loading]="this.loading"
    [dataStateChange]="this.dataStateChange" [showMenu]="false" [kendoGridState]="this.kendoGridState"
    [generateSummary]="this.getSelectedRecords" [customNavigatePath]="this.productsNavigationPath"
    [isEditingSelectedEntities]="true" [createFormGroup]="this.createFormGroup"
    [onDoubleClick]="this.openProductDetailDialog" [showButtonsColumn]="true" [showOpenButton]="true"
    [openButtonIconOverride]="'info'" [onOpenButtonClicked]="this.openProductDetailDialog">
  </ntw-grid-template>
}
